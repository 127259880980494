.item {
  display: flex;
  padding: 0.2rem 0;
  flex: 1;
  transition: 0.3s;
}

.item :deep(.tag) {
  text-align: center;
}

.item:hover {
  box-shadow: 0 0.03rem 0.06rem -0.04rem #0000001f 1f, 0 0.06rem 0.16rem #00000014 14, 0 0.09rem 0.28rem 0.08rem #0000000d 0d;
}

.item:hover h2 {
  color: #f49133 !important;
}

.item .tag_box {
  flex-shrink: 0;
  margin-top: 0.15rem;
}

.item .tag_box .tag {
  margin-bottom: 0;
}

.item .txt {
  flex: 1;
  padding-right: 0.2rem;
}

.item .txt h2 {
  font-size: 0.18rem;
  color: #333333;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.13rem;
  cursor: pointer;
}

.item .txt .des {
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.5;
}

.item .handle {
  flex-shrink: 0;
  padding-left: 0.1rem;
  display: flex;
  align-items: center;
  border-left: 0.01rem solid #d5d5d5;
}

.item .handle .handle_item {
  width: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #999;
  transition: 0.3s;
  cursor: pointer;
}

.item .handle .handle_item .anticon {
  width: 1em;
  height: 1em;
  font-size: 1.2em;
  font-weight: bold;
}

.item .handle .handle_item:hover {
  color: #f6a75c;
}