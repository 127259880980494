.search {
  display: flex;
  padding: 0.25rem 0;
}

.search .item {
  margin-right: 0.1rem;
  width: 100%;
}

.search .item:last-of-type {
  margin-right: 0;
}

.search .btn {
  width: 1.2rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.search .keyword {
  width: 3.8rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.ant-select {
  width: 100%;
}

:deep(.ant-input-affix-wrapper):hover .ant-input {
  border-color: #79f9d9 !important;
}

:deep(.ant-input) {
  width: 100%;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.18rem;
  background-color: #fff;
  border-radius: 0.03rem;
  padding: 0 0.2rem;
  border-color: transparent;
}

:deep(.ant-input):hover {
  border-color: #79f9d9 !important;
}

.submit {
  width: 100%;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.18rem;
  color: #000;
  background-color: #79f9d9;
  letter-spacing: 0;
  font-weight: bold;
}

.submit:focus,
.submit:hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  color: #000;
  box-shadow: 0 0 0.1rem 0 #79f9d9;
}

:deep(.ant-select-selection) {
  background-color: #fff;
}

.model {
  width: 3rem !important;
}