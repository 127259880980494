:deep(.ant-menu) .ant-menu-submenu-title {
  padding: 0 0.2rem !important;
  margin: 0;
  line-height: 0.6rem !important;
  height: 0.6rem !important;
  font-size: 0.18rem;
  color: #000000;
  font-weight: bold;
}

:deep(.ant-menu) .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
:deep(.ant-menu) .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: linear-gradient(to right, #f6a75c, #f6a75c);
}

:deep(.ant-menu) .ant-menu-sub {
  padding: 0.1rem 0;
}

:deep(.ant-menu) .ant-menu-item {
  padding: 0 0.2rem !important;
  margin: 0 !important;
  line-height: 0.55rem !important;
  height: 0.55rem !important;
  font-size: 0.16rem;
  color: #555555;
}

:deep(.ant-menu) .ant-menu-item .subtitle {
  display: block;
  position: relative;
}

:deep(.ant-menu) .ant-menu-item .subtitle i {
  position: absolute;
  top: 50%;
  right: -0.05rem;
  width: 0.1rem;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateY(-0.02rem);
  opacity: 0;
}

:deep(.ant-menu) .ant-menu-item .subtitle i::before {
  position: absolute;
  width: 0.06rem;
  height: 0.015rem;
  background: #fff;
  background: rgba(0, 0, 0, 0.65);
  background-image: linear-gradient(to right, #f6a75c, #f6a75c);
  background-image: none;
  border-radius: 0.02rem;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  transform: rotate(45deg) translateY(-0.022rem);
}

:deep(.ant-menu) .ant-menu-item .subtitle i:after {
  position: absolute;
  width: 0.06rem;
  height: 0.015rem;
  background: #fff;
  background: rgba(0, 0, 0, 0.65);
  background-image: linear-gradient(to right, #f6a75c, #f6a75c);
  background-image: none;
  border-radius: 0.02rem;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  transform: rotate(-45deg) translateY(0.022rem);
}

:deep(.ant-menu) .ant-menu-item-selected {
  background-color: transparent !important;
  color: #f6a75c;
}

:deep(.ant-menu) .ant-menu-item-selected::after {
  display: none;
}

:deep(.ant-menu) .ant-menu-item-selected .subtitle i {
  opacity: 1;
}

.default :deep(.ant-menu-submenu-title) {
  background-color: #dce8f3;
}

.default :deep(.ant-menu-sub) {
  background-color: #f4f7fa;
}