.breadcrumb {
  border-bottom: 0;
}

h1 {
  margin-bottom: 0.3rem;
}

h1 .cn {
  font-size: 0.28rem;
  color: #000f23;
  font-weight: bold;
}

h1 .en {
  font-size: 0.16rem;
  color: #888888;
  font-weight: normal;
}

.list_wrap {
  display: flex;
  background-color: #f8f8f8;
  background-color: #f9f9f9;
  padding: 0.6rem;
  margin-bottom: 0.8rem;
}

.list_wrap .left {
  width: 2.8rem;
  padding-right: 0.6rem;
  border-right: 0.01rem solid #dee2e8;
}

.list_wrap .right {
  flex: 1;
  padding-left: 0.6rem;
}

.sum {
  line-height: 0.6rem;
  text-align: right;
  font-size: 0.18rem;
  color: #888888;
  border-bottom: 0.01rem solid #dee2e8;
}

:deep(.ant-list) {
  min-height: 1rem;
  margin-bottom: 0.5rem;
}

:deep(.ant-list) .ant-list-item {
  border-bottom: 0;
  padding: 0;
  border-bottom: 0.01rem solid #dee2e8;
}